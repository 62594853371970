.search-results {
   
    top: 100%;

 
    z-index: 10000000;
    width: 100%;
    max-height: 200px;

  }
  